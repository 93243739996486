import { PolymerElement } from '@polymer/polymer/polymer-element.js';

class LxpSelfCheck extends PolymerElement {
  static get properties() {
    return {
      endpoint: String,
      token: String,
      value: {
        type: Number,
        value: 0
      },
      input: Object,

    };
  }

  ready(){
    super.ready();
    // console.log('LxpSelfCheck ready')
    setTimeout(()=>{
      this.input = this.querySelector('input[type="range"]');
      if(!this.input){
        console.error('The LxpSelfCheck element requires a child elemen of type:: `<input type=range>`')
      }
      this.input.addEventListener('change', ()=>{
        this.postScore();

      });
      this.setValue();
    })
  }

  setValue(){
    if( this.input){
      // console.log('LxpSelfCheck::setValue')
      this.input.value = this.value;
    }
  }

  postScore(){
    // console.log('LxpSelfCheck::postScore', this.input.value)

    fetch(this.endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json",
          "x-csrftoken": this.token
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({score: this.input.value}), // body data type must match "Content-Type" header
    })
    .then(function(response) {
      // console.log('LxpSelfCheck::postScore::response',response);
      return response.json();
    })
    .catch(error => console.error('LxpSelfCheck::postScore::Error:', error))
    .then(function(myJson) {
      // console.log('LxpSelfCheck::postScore::json',myJson);
    });
  }
}

window.customElements.define('lxp-self-check', LxpSelfCheck);
