if (!String.prototype.includes) {
  Object.defineProperty(String.prototype, 'includes', {
    value: function(search, start) {
      if (typeof start !== 'number') {
        start = 0
      }

      if (start + search.length > this.length) {
        return false
      } else {
        return this.indexOf(search, start) !== -1
      }
    }
  })
}

import 'whatwg-fetch'
import '../components/lxp-autocomplete.js';
import '../components/lxp-self-check.js';
import '../components/lxp-async-button.js';
import '../components/lxp-self-progress.js';
import '../components/lxp-likes.js';
import '../components/lxp-views.js';
import '../components/lxp-status.js';
import '../components/lxp-status-start-btn.js';
import '../components/lxp-status-start-btn-oncology.js';
import '../components/lxp-status-start-btn-hover.js';
import '../components/lxp-status-checkmark.js';
