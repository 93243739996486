import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';
import {} from '@polymer/polymer/lib/elements/dom-if.js';

class LxpStatusCheckmark extends PolymerElement {
  static get template() {
    return html`
      <template is="dom-if" if="[[isCompleted(status)]]">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="25" height="25" rx="12.5" fill="#81B052"/>
          <path d="M9.7 19.025L4 13.325L5.425 11.9L9.7 16.175L18.875 7L20.3 8.425L9.7 19.025Z" fill="white"/>
        </svg>

      </template>
    
    `;
  }

  static get properties() {
    return {
      obj_id: {
        type: Number,
        value: 0
      },
      obj_type: {
        type: String,
        value: 0
      },
      credits: {
        type: Number,
        value: 10
      },
      status: {
        type: String,
        value: 'Blossoms'
      },

    };
  }

  ready(){
    super.ready();
    const progress_data = JSON.parse(document.getElementById("progress_data").textContent)
    this.status = progress_data.Resource[this.obj_id]

  }

  isCompleted(status) {
    return status === 'Completed'
  }





}

window.customElements.define('lxp-status-checkmark', LxpStatusCheckmark);

