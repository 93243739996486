import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings.js';
import './lxp-suggestions.js';

class LxpAutocomplete extends PolymerElement{
  static get properties () {
    return {
      hidden: {
        type: Boolean
      },
      form: { type: Object},
      suggestions: { type: Object},
      inputField: { type: Object},
      endpoint: { type: String},
      formValStore: {type: String}
    };
  }
  constructor() {
    super();
    setPassiveTouchGestures(true)
  }

  ready(){
    // console.log('lxp-autocomplete ready')
    super.ready();
    this.addEventListener('keydown', (event) => {
      const keyName = event.key;
      this.chooseKeyAction(keyName, event)
    });


    setTimeout(()=>{
      this.form = this.querySelector('form');
      this.suggestions  = this.querySelector('lxp-suggestions');
      this.inputField  = this.querySelector('input[name="q"]');
      if(!this.inputField){
        console.error("<input name=\"q\"> tag is required...");
      }
      if(this.form){
        this.form.setAttribute('autocomplete', 'off')
      }
      this.addEventListener('input', (event)=>{
        // console.log('changedForm', event.target.value.length);
        if(event.target.value.length >= 3 && this.formValStore !== event.target.value){
          this.formValStore = event.target.value;
          this.inputChanged(event.target.value);
        }else {
          this.suggestions.hidden = true;
        }
      });
    });
  }

  chooseKeyAction(keyName, event ){
    switch (keyName) {
      case 'Enter':
        this.navigateToFocusedSuggestion();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.selectPreviousSuggestion();
        break;
      case 'ArrowDown':
        // event.preventDefault();
        this.selectNextSuggestion();
        break;
      case 'ArrowRight':
        this.updateSearchField();
        break;
      default:
        break;
    }
  }

  navigateToFocusedSuggestion(){
    let focused = this.suggestions.getFocusedSuggestion();
    if(focused){
      event.preventDefault();
      window.location.assign(focused.result_value)
    }
  }

  selectPreviousSuggestion(){
    this.suggestions.selectPreviousSuggestion();
    this.inputField.value = this.suggestions.getFocusedSuggestion().result_label

  }

  selectNextSuggestion(){
    this.suggestions.selectNextSuggestion();
    this.inputField.value = this.suggestions.getFocusedSuggestion().result_label
  }

  updateSearchField(){
    this.inputField.value = this.suggestions.getFocusedSuggestion().result_label;
    this.inputChanged(this.inputField.value);
    this.suggestions.removeCurrentselectionAndGetIndex();
    this.suggestions.$.repeat.notifyPath('items');
  }

  inputChanged(value){
    let request = new XMLHttpRequest();
    request.open('GET', this.endpoint + "?term=" + value, true);
    // Add the required HTTP header to handle a multipart form data POST request
    // XHR.setRequestHeader('Content-Type','multipart/form-data; boundary=' + boundary);
    request.onload = ()=>{
      if (request.status >= 200 && request.status < 400) {
        // Success!
        console.info("Form Submit Success");
        if(this.suggestions){
          this.suggestions.results = [];
          this.suggestions.results = JSON.parse(request.response).results;
          this.suggestions.term = value;
        }else{
          console.error("<lxp-suggestions> tag is required...");
        }
      } else {
        // We reached our target server, but it returned an error
        console.error("Form Submit error", request.status, request.response);
      }
    };
    request.send();
  }
}

customElements.define('lxp-autocomplete', LxpAutocomplete);
