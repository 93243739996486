import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import './lxp-sanitize.js';

class LxpSuggestions extends PolymerElement {

  static get template() {
    return html`
    <template id="repeat" is="dom-repeat" items="[[results]]" mutable-data>
      <a href="[[item.result_value]]" active$="[[shouldFocus(item.focused)]]"><lxp-sanitize html="[[applyMark(item.result_label)]]"></lxp-sanitize></a>
    </template>
      <a class="btn-all btn btn-small" href="/search/?q={{term}}"">View All Results</a>
    `;
  }

  static get properties () {
    return {
      hidden: {
        type: Boolean,
        reflectToAttribute: true
      },
      term: {
        type: "String",
      },
      results: {
        type: Array,
        notify: true,
        value: function(){return[]},
        observer: 'resultsChanged'
      }
    };
  }

  ready(){
    super.ready();
    // console.log('lxp-suggestions ready');
    document.body.addEventListener('click', ()=>{
      this.hidden = true;
    });
    document.body.addEventListener('keydown', (e)=>{
      if(e.keyCode === 27){
        this.hidden = true;
      }
    });
  }

  applyMark(value){
    // console.log(this.results)
    let regex = new RegExp(this.term, 'gi');
    return value.replace(regex, "<mark>$&</mark>");
  }

  resultsChanged(newVal, oldVal){
    // console.log('resultsChanged', this.$.repeat)
    if(newVal.length === 0){
      this.hidden = true;
      this.$.repeat.items = [];
    }else{
      this.hidden = false;
    }
  }

  getFocusedSuggestion(){
    return this.results.find((item)=>{
      return item.focused === true;
    })
  }

  selectPreviousSuggestion(){
    let index = this.removeCurrentselectionAndGetIndex();
    // console.log('selectPreviousSuggestion', index, this.results)
    if(index !== false && index > 0){
      index--
    }else{
      index = this.results.length - 1;
    }

    this.results[index].focused = true;
    // this.$.repeat.items = this.results.slice()
    this.$.repeat.notifyPath('items')
  }

  removeCurrentselectionAndGetIndex(){
    let currentSelection = this.getFocusedSuggestion();
    if(currentSelection){
      currentSelection.focused = false;
      return this.results.findIndex(x => x.slug == currentSelection.slug);
    }else{
      return false
    }
  }

  selectNextSuggestion(){
    let index = this.removeCurrentselectionAndGetIndex();
    // console.log('selectNextSuggestion', index, this.results)
    if(index !== false && index < this.results.length - 1){
      index += 1;
    }else{
      index = 0;
    }

    this.results[index].focused = true;
    // this.$.repeat.items = this.results.slice()
    this.$.repeat.notifyPath('items')
  }

  shouldFocus(bool){
    // console.log("bool", bool)
    if(bool){
      return true;
    }else{
      return false;
    }
  }
  _attachDom(dom) {
    // console.log('_attachDom', this, dom)
    this.appendChild(dom);
  }

}

customElements.define('lxp-suggestions', LxpSuggestions);
