import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';



class LxpViews extends PolymerElement {

  static get properties() {
    return {
      endpoint: String,
      token: String,
      value: String,
      method: {
        type: String,
        value: 'POST',
        reflectToAttribute: true
      },
      obj_id: {
        type: Number,
        value: 0
      },
      obj_type: {
        type: String,
        value: 0
      },
    };
  }

  ready(){
    super.ready();

    console.log('lxp-views ready');

    this.postStatus();
  }

  postStatus(){

    fetch(this.endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json",
          "x-csrftoken": this.token
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({obj_type: this.obj_type, obj_id: this.obj_id}), // body data type must match "Content-Type" header
    })
    .then(function(response) {
      console.log('LxpViews::postStatus::response',response);
      return response.json();
    })
    .catch(error => console.error('LxpViews::postStatus::Error:', error))
    .then(function(myJson) {
       console.log('LxpViews::postStatus::json',myJson);

    });

  }

}

window.customElements.define('lxp-views', LxpViews);
