import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';

class LxpAsyncButton extends PolymerElement {
  static get properties() {
    return {
      endpoint: {
        type: String,
        value: '/',
      },
      token: String,
      method: {
        type: String,
        value: 'POST',
        reflectToAttribute: true
      },
      value: {
        type: Object,
        value: function(){
          return {};
        }
      },

    };
  }

  ready(){
    super.ready();
    this.addEventListener('click', ()=>{
      this.makeRequest();
    })
  }

  makeRequest(){
    fetch(this.endpoint, {
      method: this.method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json",
          "x-csrftoken": this.token
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({score: this.value}), // body data type must match "Content-Type" header
    })
    .then((response)=>{
      // console.log('LxpAsyncButton::request::response',response);
      if(this.method === "POST"){
        this.set('method', 'DELETE');
      }else{
        this.set('method', 'POST');
      }
      return response.json();
    })
    .catch(error => console.error('LxpAsyncButton::request::Error:', error))
    .then(function(myJson) {
      // console.log('LxpAsyncButton::request::json',myJson);
    });
  }

}

window.customElements.define('lxp-async-button', LxpAsyncButton);
