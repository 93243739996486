import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';
import {} from '@polymer/polymer/lib/elements/dom-if.js';

class LxpStatusStartBtnOncology extends PolymerElement {
  static get template() {
    return html`
      
      <template is="dom-if" if="[[showReview(status)]]">
       <a style="color: #005baa; background-color: white; border-radius: 3px; padding: 5px 15px; border: #005baa solid 1px">Review</a>
      </template>
      <template is="dom-if" if="[[!showReview(status)]]">
         <a style="background-color: #005baa; color: white; border-radius: 3px; padding: 5px 20px;">Start</a>
        
      </template>
    `;
  }

  static get properties() {
    return {
      obj_id: {
        type: Number,
        value: 0
      },
      obj_type: {
        type: String,
        value: 0
      },
      credits: {
        type: Number,
        value: 10
      },
      status: {
        type: String,
        value: 'Blossoms'
      },

    };
  }

  ready(){
    super.ready();
    const progress_data = JSON.parse(document.getElementById("progress_data").textContent)
    this.status = progress_data.Resource[this.obj_id]

  }

  showReview(status) {
    return status === 'Completed'
  }





}

window.customElements.define('lxp-status-start-btn-oncology', LxpStatusStartBtnOncology);

