import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';

class LxpSanitize extends PolymerElement{
  static get properties() {
    return {
      html: {type: String, observer: 'update'},

    };
  }

  ready(){
    super.ready();
    setTimeout(()=>{
      this.innerHTML = filterXSS(this.html)
    })
  }

  update(newVal, oldVal){
    // console.log('update', newVal)
    this.innerHTML = filterXSS(newVal)
  }
}

window.customElements.define('lxp-sanitize', LxpSanitize);
