import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';


class LxpSelfProgress extends PolymerElement {
  static get properties() {
    return {
      endpoint: String,
      token: String,
      value: String,
      method: {
        type: String,
        value: 'POST',
        reflectToAttribute: true
      },
      obj_id: {
        type: Number,
        value: 0
      },
      obj_type: {
        type: String,
        value: 0
      },
      current_status: {
        type: String,
        value: 0
      },
      input: Object,
    };
  }

  ready(){
    super.ready();
    var current_status = this.current_status;

    setTimeout(()=>{
      this.input = this.querySelector('select.learning-plan-select');
      var options = this.querySelectorAll('option.learning-plan-option');
      options.forEach(function(element) {
        if (element.value === current_status) {
          element.selected = true;
          var dataValue = current_status;
          element.parentNode.classList.add(dataValue.toLowerCase());
        }
      });

      this.addEventListener('change', ()=>{
        this.postProgress();
        this.input.getAttribute('tabindex');
        this.option = this.querySelectorAll('option');
        this.setValue();
      });

    });
  }

  setValue(){
    if( this.input ){
      var newValue = this.input.value.replace('-', ' ');
      var newStatusBadge = this.input.closest('div').querySelector('.status-badge');
      var newStatusSelect = this.input.closest('div').querySelector('.learning-plan-select');

      newStatusBadge.textContent = ' ' + newValue;
      newStatusBadge.className = '';
      newStatusBadge.classList.add('status-badge', this.input.value.toLowerCase());

      newStatusSelect.className = '';
      newStatusSelect.classList.add('form-control', 'learning-plan-select', this.input.value.toLowerCase());
    }
  }



  postProgress(){

    fetch(this.endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json",
          "x-csrftoken": this.token
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({obj_type: this.obj_type, obj_id: this.obj_id, progress_status: this.childNodes[1].value}), // body data type must match "Content-Type" header
    })
    .then(function(response) {
      // console.log('LxpSelfProgress::postProgress::response',response);
      return response.json();
    })
    .catch(error => console.error('LxpSelfProgress::postProgress::Error:', error))
    .then(function(myJson) {
      // console.log('LxpSelfProgress::postProgress::json',myJson);

    });

  }

}

window.customElements.define('lxp-self-progress', LxpSelfProgress);