import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import 'xss/dist/xss.js';
import {} from '@polymer/polymer/lib/elements/dom-if.js';

class LxpStatusStartBtnHover extends PolymerElement {
  static get template() {
    return html`

      <template is="dom-if" if="[[showReview(status)]]">
        <style>
          .button {
            color: #005BAA;
            background-color: white;
            border: 2px solid #005BAA;
            border-radius: 0.25rem;
            padding: 5px 15px;
            cursor: pointer;
          }
          .button:hover, .button:focus {
            color: white;
            background-color: #005BAA;
            border-color: #005BAA;
            text-decoration: none;
          }
        </style>
        <a class="button">Review</a>
      </template>
      <template is="dom-if" if="[[!showReview(status)]]">
        <style>
          .button {
            color: white;
            background-color: #005BAA;
            border: 2px solid #005BAA;
            border-radius: 0.25rem;
            padding: 5px 15px;
            cursor: pointer;
          }
          .button:hover, .button:focus {
            color: #005BAA;
            background-color: white;
            border-color: #005BAA;
            text-decoration: none;
          }
        </style>
        <a class="button">Start</a>
      </template>
    `;
  }

  static get properties() {
    return {
      obj_id: {
        type: Number,
        value: 0
      },
      obj_type: {
        type: String,
        value: 0
      },
      credits: {
        type: Number,
        value: 10
      },
      status: {
        type: String,
        value: 'Blossoms'
      },

    };
  }

  ready(){
    super.ready();
    const progress_data = JSON.parse(document.getElementById("progress_data").textContent)
    this.status = progress_data.Resource[this.obj_id]

  }

  showReview(status) {
    return status === 'Completed'
  }





}

window.customElements.define('lxp-status-start-btn-hover', LxpStatusStartBtnHover);

